<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <v-card>
        <!-- Información básica de la empresa -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-h5 white--text">{{ empresa.razonSocial }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{
              empresa.nombreFantasia
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <!-- Detalles de la empresa -->
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                ><strong>CUIT:</strong> {{ empresa.cuit }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Fecha de Inicio:</strong>
                {{ moment(empresa.fecha_inicio_actividades).format("ll") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <strong>Email:</strong> {{ empresa.email }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ empresa.direccion }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ empresa.direccion }},
                {{ empresa.localidad ? empresa.localidad.nombre : "N/A" }} -
                {{
                  empresa.localidad && empresa.localidad.provincia
                    ? empresa.localidad.provincia.nombre
                    : "N/A"
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Agrega más detalles según sea necesario -->
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";

export default {
  name: "EmpresaDetailDatos",
  components: {
    LayoutBasic,
  },
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
